import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { ApiOperation, GetMakeTypeList } from '@/api/updateUserInfo/Journal';
export default {
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      value1: null,
      title: 'API操作日志',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      loading: true,
      tableData: [],
      username: '',
      start_time: '',
      end_time: '',
      make_type: '',
      optionsvalue: '',
      options: []
    };
  },
  components: {
    Head: Head
  },
  mounted: function mounted() {
    if (this.course == 1) {} else {
      this.GetList();
      this.GetMakeType();
    }
  },
  methods: {
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this2 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this2.GetList();
      });
    },
    //获取列表
    GetList: function GetList() {
      var _this3 = this;
      this.loading = true;
      ApiOperation({
        page: this.query.page,
        pagesize: this.query.pagesize,
        username: this.username,
        start_time: this.start_time,
        end_time: this.end_time,
        make_type: this.make_type
      }).then(function (respomse) {
        _this3.loading = false;
        _this3.tableData = respomse.data.data;
        _this3.query.total = respomse.data.total;
      });
    },
    GetMakeType: function GetMakeType() {
      var _this4 = this;
      GetMakeTypeList().then(function (res) {
        _this4.options = res.data;
      });
    },
    //查询
    handleFilter: function handleFilter() {
      this.GetList();
    },
    //操作类型
    handleType: function handleType(val) {
      var _this5 = this;
      this.make_type = val;
      this.$nextTick(function () {
        _this5.GetList();
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this6 = this;
      if (this.value1 == null) {
        this.$nextTick(function () {
          _this6.GetList();
        });
      } else {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this6.GetList();
        });
      }
    }
  }
};